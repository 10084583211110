import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../utils/messages';
import UtilsService from '../../services/utils.service';
import RoleService from '../../services/role.service';


const RoleForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [permissions, setPermissions] = useState([]);
    const loadPermissions = async () => {
        UtilsService.getRelatedField('users', 'CustomPermission')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setPermissions(res);
        })
    }

    let formFields = {
        name: yup.string().required('Este campo es requerido'),
        permissions: yup.array().min(1, 'Debes elegir al menos un permiso').required()
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            permissions: [],
        }
    });

    const onSubmitHandler = async (data) => {
        setSaving(true)
        try {
            if (!id) {
                await RoleService.createRole(data);
                setSuccessMessage('Rol creado exitosamente');
            } else {
                await RoleService.updateRole(id, data);
                setSuccessMessage('Rol actualizado correctamente');
            }
            navigate('/roles');
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };

    const loadRole = async () => {
        RoleService.getRole(id)
        .then(response => {
            setValue('name', response.data.group.name)
            setValue('permissions', response.data.group.permissions.map((i) => {
                return i.id.toString()
            }))
        })
    }

    useEffect(() => {
        loadPermissions();
        if(id) loadRole();
    }, []);


    return <Layout title={!id ? 'Nuevo rol': 'Editar rol'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['name'].exclusiveTests.required && <span className="text-danger">*</span>} Nombre</label>
                    <input type="text" className='form-control' {...register('name')} />
                    {errors.name ? <div className='text-danger'>{errors.name?.message}</div> : ''}
                </div>
                <div className='mb-3 col-12'>
                    <label className='form-label'>{schema.fields['permissions'].exclusiveTests.required && <span className="text-danger">*</span>} Permisos</label>
                    <div className='row'>
                        {
                            permissions.map((perm) => {
                                return <div key={perm.label} className='col-md-6 col-lg-4 col-xl-3'> 
                                    <div className="form-check mb-1">
                                        <input type="checkbox" id={'perm' + perm.value} value={perm.value} className="form-check-input" {...register('permissions')}/>
                                        <label htmlFor={'perm' + perm.value}  className="form-check-label">{perm.label}</label>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {errors.permissions ? <div className='text-danger'>{errors.permissions?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/roles')}>Regresar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default RoleForm