import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { showMessage, setSuccessMessage, displayErrorMessages } from '../../utils/messages';

import UtilsService from '../../services/utils.service';
import UserService from '../../services/user.service';
import { hasPermissions } from '../../utils/permissions';



const UserForm = () => {
    const userPermissions = useSelector(state => state.Auth.permissions);
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState();
    const loadDepartments = async (reload=false) => {
        UtilsService.getRelatedField('departments', 'Department')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setDepartments(res);
            if(reload) showMessage('success', 'Departamentos actualizados correctamente')
        })
    }
    const departmentOptions = useMemo(() => {
        const filtered = departments.filter((department) =>
            department.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [departments, searchTerm]);

    const [roles, setRoles] = useState([]);
    const loadRoles = async (reload=false) => {
        UtilsService.getRelatedField('auth', 'Group')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setRoles(res);
            if(reload) showMessage('success', 'Roles actualizados correctamente')
        })
    }

    const [permissions, setPermissions] = useState([]);
    const loadPermissions = async () => {
        UtilsService.getRelatedField('users', 'CustomPermission')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setPermissions(res);
        })
    }


    let formFields = {
        first_name: yup.string().required('Este campo es requerido'),
        last_name: yup.string().required('Este campo es requerido'),
        email: yup.string().required('Este campo es requerido'),
        department: yup.string().required('Este campo es requerido'),
        user_permissions: yup.array().nullable(),
        groups: yup.array().nullable(),
        is_active: yup.boolean()
    }

    if(!id){
        formFields = {
            ...formFields,
            password: yup.string().required('Este campo es requerido')
                .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,16}$/,'El formato de la contraseña es incorrecto'),
            confirm_password: yup.string().required('Este campo es requerido').oneOf([yup.ref('password'), null], 'La contraseña no coincide')
        }
    }

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            user_permissions: [],
            groups: []
        }
    });

    const onSubmitHandler = async (data) => {
        setSaving(true)
        try {
            if (!id) {
                await UserService.createUser(data);
                setSuccessMessage('Departamento creado exitosamente');
            } else {
                await UserService.updateUser(id, data);
                setSuccessMessage('Departamento actualizado correctamente');
            }
            navigate('/users');
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };

    const loadUser = async () => {
        UserService.getUser(id)
        .then(response => { console.log(response)
            setValue('first_name', response.data.first_name)
            setValue('last_name', response.data.last_name)
            setValue('email', response.data.email)

            if(response.data.department?.id){
                setValue('department', response.data.department.id)
                setSelectedDepartment({value: response.data.department.id, label: response.data.department.name})
            }

            setValue('groups', response.data.groups.map((i) => {
                return i.id.toString()
            }))
            setValue('user_permissions', response.data.user_permissions.map((i) => {
                return i.id.toString()
            }))
            setValue('is_active', response.data.is_active)
        })
    }

    useEffect(() => {
        loadDepartments();
        loadRoles();
        loadPermissions();
        if(id) loadUser();
    }, []);

    return <Layout title={!id ? 'Nuevo usuario': 'Editar usuario'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['first_name'].exclusiveTests.required && <span className="text-danger">*</span>} Nombre</label>
                    <input type="text" className='form-control' {...register('first_name')} />
                    {errors.first_name ? <div className='text-danger'>{errors.first_name?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['last_name'].exclusiveTests.required && <span className="text-danger">*</span>} Apellidos</label>
                    <input type="text" className='form-control' {...register('last_name')} />
                    {errors.last_name ? <div className='text-danger'>{errors.last_name?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['email'].exclusiveTests.required && <span className="text-danger">*</span>} Correo electrónico</label>
                    <input type="email" className='form-control' {...register('email')} />
                    {errors.email ? <div className='text-danger'>{errors.email?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>{schema.fields['department'].exclusiveTests.required && <span className="text-danger">*</span>} Departamento</label>
                        {hasPermissions(['departments.cus_add_department'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/departments/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadDepartments(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="department"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={departmentOptions}
                    filterOption={() => true}
                    onInputChange={handleInputChange}
                    onChange={(option, action) => {setValue('department', option ? option.value : ''); setSelectedDepartment(option)}}
                    value={selectedDepartment}>
                    </Select>
        
                    {errors.department ? <div className='text-danger'>{errors.department?.message}</div> : ''}
                </div>

                {id && 
                    <div className='mb-3 col-md-6'>
                        <label className='form-label'>{schema.fields['is_active'].exclusiveTests.required && <span className="text-danger">*</span>} Estatus</label>
                        <div className="form-check form-switch">
                            <input type="checkbox" id="estatus" className="form-check-input" {...register('is_active')} />
                            <label title="" htmlFor="estatus" className="form-check-label">Activo / Inactivo</label>
                        </div>
                    </div>
                }

                {!id &&
                    <div className='mb-3 col-md-6'>
                        <label className='form-label'>{schema.fields['password'].exclusiveTests.required && <span className="text-danger">*</span>} Contraseña</label>
                        <input type="password" className='form-control' {...register('password')}/>
                        <small className="form-text">La contraseña debe incluir al menos un número, una mayúscula, una minúscula y debe tener entre 10-16 caracteres.</small>
                        {errors.password ? <div className='text-danger'>{errors.password?.message}</div> : ''}
                    </div>
                }
                {!id &&    
                    <div className='mb-3 col-md-6'>
                        <label className='form-label'>{schema.fields['confirm_password'].exclusiveTests.required && <span className="text-danger">*</span>} Confirmar contraseña</label>
                        <input type="password" className='form-control' {...register('confirm_password')}/>
                        {errors.confirm_password ? <div className='text-danger'>{errors.confirm_password?.message}</div> : ''}
                    </div>
                }
    
                <div className='mb-3 col-12'>
                    <div className='d-flex'>
                        <label className='form-label'>{schema.fields['groups'].exclusiveTests.required && <span className="text-danger">*</span>} Roles de usuario</label>
                        {hasPermissions(['users.cus_add_role'], userPermissions) &&
                            <div className='ms-1 mt-n1'>
                                (
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/roles/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadRoles(true)}}><i className='dripicons-retweet'></i></button>
                                )
                            </div>
                        }
                    </div>
                    
                    <div className='row'>
                        {
                            roles.map((role) => {
                                return <div key={role.label} className='col-md-6 col-lg-4 col-xl-3'> 
                                    <div className="form-check mb-1">
                                        <input type="checkbox" id={'role' + role.value} value={role.value} className="form-check-input" {...register('groups')}/>
                                        <label htmlFor={'role' + role.value}  className="form-check-label">{role.label}</label>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className='mb-3 col-12'>
                    <label className='form-label'>{schema.fields['user_permissions'].exclusiveTests.required && <span className="text-danger">*</span>} Permisos específicos de usuario</label>
                    <div className='row'>
                        {
                            permissions.map((perm) => {
                                return <div key={perm.label} className='col-md-6 col-lg-4 col-xl-3'> 
                                    <div className="form-check mb-1">
                                        <input type="checkbox" id={'perm' + perm.value} value={perm.value} className="form-check-input" {...register('user_permissions')}/>
                                        <label htmlFor={'perm' + perm.value}  className="form-check-label">{perm.label}</label>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/users')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default UserForm;