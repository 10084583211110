import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment/moment';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages, showMessage} from '../../utils/messages';
import { hasPermissions } from '../../utils/permissions';
import LeadService from '../../services/lead.service';
import UtilsService from '../../services/utils.service';


const LeadForm = () => {
    const userPermissions = useSelector(state => state.Auth.permissions);
    const canViewLeadsFromOtherSellers = hasPermissions(['leads.cus_view_leads_from_other_sellers'], userPermissions)
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const [sellers, setSellers] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState();
    const loadSellers = async (reload=false) => {
        UtilsService.getRelatedField('sellers', 'Seller')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setSellers(res);
            if(reload) showMessage('success', 'Vendedores actualizados correctamente')
        })
    }
    const sellerOptions = useMemo(() => {
        const filtered = sellers.filter((seller) =>
            seller.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [sellers, searchTerm]);

    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const loadCustomers = async (reload=false) => {
        LeadService.getRelatedCustomers()
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setCustomers(res);
            if(reload) showMessage('success', 'Clientes actualizados correctamente')
        })
    }
    const customerOptions = useMemo(() => {
        const filtered = customers.filter((customer) =>
            customer.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [customers, searchTerm]);

    const [salesChannels, setSalesChannels] = useState([]);
    const [selectedSalesChannel, setSelectedSalesChannel] = useState();
    const loadSalesChannels = async (reload=false) => {
        UtilsService.getFieldChoices('leads', 'Lead', 'sales_channel')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt[0], label: opt[1]};
            });
            setSalesChannels(res);
        })
    }
    const salesChannelOptions = useMemo(() => {
        const filtered = salesChannels.filter((salesChannel) =>
            salesChannel.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [salesChannels, searchTerm]);

    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();
    const loadStatus = async (reload=false) => {
        UtilsService.getFieldChoices('leads', 'Lead', 'status')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt[0], label: opt[1]};
            });
            setStatus(res);
        })
    }
    const statusOptions = useMemo(() => {
        const filtered = status.filter((statu) =>
            statu.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [status, searchTerm]);

    let formFields = {
        client_request: yup.string().required('Este campo es requerido')
        .max(2000, 'EL máximo de caracteres es 2000'),
        observations: yup.string().max(2000, 'EL máximo de caracteres es 2000').nullable(),
        budget: yup.number()
            .positive('Este campo debe ser un número positivo')
            .transform(value => (isNaN(value) ? undefined : value)),
        contact: yup.string().required('Este campo es requerido'),
        sales_channel: yup.string().required('Este campo es requerido'),
        status: yup.string().required('Este campo es requerido'),
        requested_date: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr)
    }

    if(canViewLeadsFromOtherSellers) formFields['seller'] = yup.string().required('Este campo es requerido')

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = async (data) => {
        if(data.requested_date) data.requested_date = moment(data.requested_date).format('YYYY-MM-DD')
        setSaving(true)
        try {
            if (!id) {
                await LeadService.createLead(data);
                setSuccessMessage('Lead creado exitosamente');
            } else {
                await LeadService.updateLead(id, data);
                setSuccessMessage('Lead actualizado correctamente');
            }
            navigate('/leads');
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };

    const loadLead = async () => {
        LeadService.getLead(id)
        .then(response => {
            setValue('client_request', response.data.client_request)
            setValue('observations', response.data.observations)
            setValue('budget', response.data.budget)
            setValue('requested_date', response.data.requested_date)
            setValue('contact', response.data.contact.id)
            setSelectedCustomer({value: response.data.contact.id, label: response.data.contact.name + ' - ' + response.data.contact.customer.company})
            setValue('seller', response.data.seller.id)
            setSelectedSeller({value: response.data.seller.id, label: response.data.seller.identifier + ' - ' + response.data.seller?.user?.first_name + ' ' + response.data.seller?.user?.last_name})
            setValue('status', response.data.status)
            setSelectedStatus({value: response.data.status, label: response.data.status_display})
            setValue('sales_channel', response.data.sales_channel)
            setSelectedSalesChannel({value: response.data.sales_channel, label: response.data.sales_channel_display})
        })
    }

    useEffect(() => {
        loadSellers();
        loadCustomers();
        loadStatus();
        loadSalesChannels();
        if(id) loadLead();
    }, []);


    return <Layout title={!id ? 'Nuevo lead': 'Editar lead'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <div className='d-flex'>
                        <label className='form-label ms-1'>{schema.fields['contact'].exclusiveTests.required && <span className="text-danger">*</span>} Cliente</label>
                        {hasPermissions(['customers.cus_add_customer'], userPermissions) &&
                        <div className='ms-1 mt-n1'>
                            (
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/customers', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                            <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadCustomers(true)}}><i className='dripicons-retweet'></i></button>
                            )
                        </div>
                        }
                    </div>
                    
                    <Controller
                    name="contact"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={customerOptions}
                    filterOption={() => true}
                    onInputChange={handleInputChange}
                    onChange={(option, action) => {setValue('contact', option ? option.value : ''); setSelectedCustomer(option)}}
                    value={selectedCustomer}>
                    </Select>
        
                    {errors.contact ? <div className='text-danger'>{errors.contact?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label ms-1'>{schema.fields['sales_channel'].exclusiveTests.required && <span className="text-danger">*</span>} Canal de venta</label>
                    <Controller
                    name="sales_channel"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={salesChannelOptions}
                    filterOption={() => true}
                    onInputChange={handleInputChange}
                    onChange={(option, action) => {setValue('sales_channel', option ? option.value : ''); setSelectedSalesChannel(option)}}
                    value={selectedSalesChannel}>
                    </Select>
        
                    {errors.sales_channel ? <div className='text-danger'>{errors.sales_channel?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label ms-1'>{schema.fields['status'].exclusiveTests.required && <span className="text-danger">*</span>} Estatus</label>
                    <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={statusOptions}
                    filterOption={() => true}
                    onInputChange={handleInputChange}
                    onChange={(option, action) => {setValue('status', option ? option.value : ''); setSelectedStatus(option)}}
                    value={selectedStatus}>
                    </Select>
        
                    {errors.status ? <div className='text-danger'>{errors.status?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['client_request'].exclusiveTests.required && <span className="text-danger">*</span>} Solicitud del cliente</label>
                    <textarea cols="50" rows="8" className='form-control' {...register('client_request')}></textarea>
                    {errors.client_request ? <div className='text-danger'>{errors.client_request?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['budget'].exclusiveTests.required && <span className="text-danger">*</span>} Presupuesto</label>
                    <input type="number" step="0.01" className='form-control' {...register('budget')} />
                    {errors.budget ? <div className='text-danger'>{errors.budget?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['requested_date'].exclusiveTests.required && <span className="text-danger">*</span>} Fecha requerida</label>
                    <input type="date" className='form-control' {...register('requested_date')} />
                    {errors.requested_date ? <div className='text-danger'>{errors.requested_date?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['observations'].exclusiveTests.required && <span className="text-danger">*</span>} Observaciones</label>
                    <textarea cols="50" rows="8" className='form-control' {...register('observations')}></textarea>
                    {errors.observations ? <div className='text-danger'>{errors.observations?.message}</div> : ''}
                </div>
                {
                    canViewLeadsFromOtherSellers &&
                    <div className='mb-3 col-md-6'>
                        <div className='d-flex'>
                            <label className='form-label ms-1'>{schema.fields['seller'].exclusiveTests.required && <span className="text-danger">*</span>} Ejecutivo</label>
                            {hasPermissions(['sellers.cus_add_seller'], userPermissions) &&
                            <div className='ms-1 mt-n1'>
                                (
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/sellers/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadSellers(true)}}><i className='dripicons-retweet'></i></button>
                                )
                            </div>
                            }
                        </div>
                        
                        <Controller
                        name="seller"
                        control={control}
                        render={({ field }) => (
                            <input type="hidden" {...field} value={field.value} />
                        )}
                        >
                        </Controller>
                        
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={sellerOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setValue('seller', option ? option.value : ''); setSelectedSeller(option)}}
                        value={selectedSeller}>
                        </Select>
            
                        {errors.seller ? <div className='text-danger'>{errors.seller?.message}</div> : ''}
                    </div>
                }
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/leads')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default LeadForm