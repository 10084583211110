import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { setSuccessMessage, displayErrorMessages } from '../../../utils/messages';
import Layout from '../../layout/Layout';
import CardMenu from '../../layout/components/CardMenu';
import CustomerService from '../../../services/customer.service';


const DeleteBillingProfileForm = () => {
    const navigate = useNavigate();
    const { customerId, id } = useParams();
    const [billinProfile, setBillingProfile] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDeleting, setDeleting] = useState(false);

    const loadBillingProfile = async () => {
        CustomerService.getBillingProfile(id)
        .then(response => {
            setBillingProfile(response.data)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isDeleting) {
          return;
        }
        setDeleting(true);
        try {
          await CustomerService.deleteBillingProfile(id);
          setSuccessMessage('Datos de facturación eliminados exitosamente');
          navigate('/customers/' + customerId + '/billing-profiles');
        } catch (error) {
          displayErrorMessages(error.response.data);
        } finally {
          setDeleting(false);
        }
    };

    useEffect(() => {
        loadBillingProfile();
    }, [])

    return <Layout title='Eliminar datos de facturación'>
        <CardMenu>
            <div role="alert" className="fade bg-white alert alert-danger show">
                <h4 className="alert-heading">¿Deseas eliminar los datos de facturación de {billinProfile.business_name}?</h4>
                <p>Esta acción no se puede deshacer</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers/' + customerId + '/billing-profiles')}>Cancelar</button>
                        <button type='submit' className='btn btn-danger' disabled={isDeleting}>{isDeleting ? 'Eliminando...' : 'Eliminar'}</button>
                    </div>
                </div>
            </form>            
        </CardMenu>
    </Layout>
}

export default DeleteBillingProfileForm