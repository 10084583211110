import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es-mx';

import { hasPermissions } from '../../../utils/permissions';
import { checkSuccessMessage } from '../../../utils/messages';
import Actions from '../../layout/components/Actions';
import Layout from '../../layout/Layout'
import CardMenu from '../../layout/components/CardMenu'
import CustomerService from '../../../services/customer.service';


const BillingProfileList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const [loadingData, setLoadingData] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()
    const currentPath = location.pathname + location.search;
    
    const [customer, setCustomer] = useState({});
    const loadCustomer = async () => {
        setLoadingData(true);
        CustomerService.getCustomer(id)
        .then(response => {
            setCustomer(response.data)
            setLoadingData(false);
        })
    }

    moment.locale('es-mx')

    useEffect(() => {
        loadCustomer();
        checkSuccessMessage();
    }, []);

    return <Layout title={'Datos de facturación de ' + customer.company}>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers')}>Regresar a clientes</button>
                {hasPermissions(['customers.cus_add_billingprofile'], permissions) && 
                    <Link 
                    to={'/customers/' + id + '/billing-profiles/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nuevos datos de facturación
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-bordered'>
                    <thead className='table-light'>
                        <tr>
                            <th>Razón social</th>
                            <th>RFC</th>
                            <th>Uso de CFDI</th>
                            <th>Régimen fiscal</th>
                            <th>Dirección</th>
                            <th>Creado por</th>
                            <th>Última modificación</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                customer.billing_profiles?.length ? 
                                    customer.billing_profiles.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.business_name}</td>
                                            <td>{obj.rfc}</td>
                                            <td>{obj.cfdi_display}</td>
                                            <td>{obj.tax_regime_display}</td>
                                            <td>{obj.line1} {obj.line2}, {obj.municipality}, {obj.state}, C.P.{obj.postal_code}</td>
                                            <td>{obj.created_by} el {moment(obj.created_at).format('LLLL')}</td>
                                            <td>{obj.last_updated_by} el {moment(obj.last_updated_at).format('LLLL')}</td>
                                            <td>
                                                <Actions
                                                actions={[
                                                    {'label': 'Editar', 'link': '/customers/' + id + '/billing-profiles/edit/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_change_billingprofile'], permissions)},
                                                    {'label': 'Eliminar', 'link': '/customers/' + id + '/billing-profiles/delete/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_delete_billingprofile'], permissions)}
                                                ]}></Actions>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </CardMenu>
    </Layout>
}

export default BillingProfileList