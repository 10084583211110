import api from './api'

class LeadService {
    getLeads(params={}){
        return api.get('/api/leads/', {params});
    }

    getLead(id){
        return api.get('/api/leads/' + id + '/')
    }

    createLead(payload={}){
        return api.post('/api/leads/', payload)
    }

    updateLead(id, payload={}){
        return api.put('/api/leads/' + id + '/', payload)
    }

    deleteLead(id){
        return api.delete('/api/leads/' + id + '/')
    }

    getRelatedCustomers(){
        return api.get('/api/leads/related-customers/')
    }

    getLeadReport(params={}){
        return api.get('/api/leads/leads-report/', {params})
    }
}

export default new LeadService()