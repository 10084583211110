import CustomerForm from '../../components/customers/CustomerForm'
import CustomerList from '../../components/customers/CustomerList'
import DeleteCustomerForm from '../../components/customers/DeleteCustomerForm'
import CustomerDetail from '../../components/customers/CustomerDetail'
import BillingProfileList from '../../components/customers/billing_profiles/BillingProfileList'
import BillingProfileForm from '../../components/customers/billing_profiles/BillingProfileForm'
import DeleteBillingProfileForm from '../../components/customers/billing_profiles/DeleteBillingProfileForm'
import CustomerContactForm from '../../components/customers/contacts/ContactForm'
import DeleteCustomerContactForm from '../../components/customers/contacts/DeleteContactForm'
import CustomerContactList from '../../components/customers/contacts/ContactList'


const customerRoutes = [
    {path: '/customers', permission: ['customers.cus_view_customer'], component: <CustomerList/>},
    {path: '/customers/:id', permission: ['customers.cus_view_customer'], component: <CustomerDetail/>},
    {path: '/customers/add', permission: ['customers.cus_add_customer'], component: <CustomerForm/>},
    {path: '/customers/edit/:id', permission: ['customers.cus_change_customer'], component: <CustomerForm/>},
    {path: '/customers/delete/:id', permission: ['customers.cus_delete_customer'], component: <DeleteCustomerForm/>},
    {path: '/customers/:id/billing-profiles', permission: ['customers.cus_view_billingprofile'], component: <BillingProfileList/>},
    {path: '/customers/:customerId/billing-profiles/add', permission: ['customers.cus_add_billingprofile'], component: <BillingProfileForm/>},
    {path: '/customers/:customerId/billing-profiles/edit/:id', permission: ['customers.cus_change_billingprofile'], component: <BillingProfileForm/>},
    {path: '/customers/:customerId/billing-profiles/delete/:id', permission: ['customers.cus_delete_billingprofile'], component: <DeleteBillingProfileForm/>},
    {path: '/customers/:id/contacts', permission: ['customers.cus_view_contact'], component: <CustomerContactList/>},
    {path: '/customers/:customerId/contacts/add', permission: ['customers.cus_add_contact'], component: <CustomerContactForm/>},
    {path: '/customers/:customerId/contacts/edit/:id', permission: ['customers.cus_change_contact'], component: <CustomerContactForm/>},
    {path: '/customers/:customerId/contacts/delete/:id', permission: ['customers.cus_delete_contact'], component: <DeleteCustomerContactForm/>},
]

export default customerRoutes