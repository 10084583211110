import api from './api';
import store from '../redux/store';
import AuthService from './auth.service';
import { logout } from '../redux/actions/auth';

let refreshingToken = false; // Variable to track if the token is already being refreshed

const setup = () => {

    api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {

        if (error.config.url !== '/api/users/login/' && error.response){
          if (error.response.status === 401){
            if (error.config.url === '/api/users/login/refresh/'){
              return store.dispatch(logout());
            } else {
              if (!refreshingToken) {
                refreshingToken = true; // Indicates that the token is being refreshed
                try {
                  let refreshTokenResponse;
                  
                  while (!refreshTokenResponse || !refreshTokenResponse.data) {
                    refreshTokenResponse = await AuthService.refreshToken(localStorage.getItem('refresh'));
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // wait 1 second
                  }
                  
                  localStorage.setItem('access', refreshTokenResponse.data.access);
                  localStorage.setItem('refresh', refreshTokenResponse.data.refresh);
                  
                  refreshingToken = false; // Token refresh completed
                  
                  if (error.config.url === '/api/users/login/verify/') {
                    return true;
                  }
                  return api(error.config);
                } catch (e) {
                  refreshingToken = false; // Clear state if update fails
                  if (error.config.url === '/api/users/login/verify/') {
                    return store.dispatch(logout());
                  }
                  return Promise.reject(e);
                }
              } else {
                // If the token is already being refreshed, please wait before continuing
                await new Promise((resolve) => setTimeout(resolve, 1000)); // wait 1 second
                return api(error.config);
              }
            }
          } else if (error.response.status === 404) {
            return window.location = '/not-found';
          } else if (error.response.status === 403) {
            return window.location = '/forbidden';
          } else if (error.response.status === 500) {
            return window.location = '/server-error';
          }
        }

        return Promise.reject(error);

      }
    );

  };

export default setup;
