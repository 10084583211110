import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';


const Pagination = (props) => {
    const {previous, next, num_pages} = props;
    const [searchParams, setSearchParams] = useSearchParams();

    const handlePage = (pageNumber) => {
        setSearchParams(
            {
                ...Object.fromEntries(new URLSearchParams(searchParams)), 
                page: parseInt(searchParams.get('page') || 1) + pageNumber
            }
        )
        window.location.reload()
    }

    return <div className='d-flex justify-content-center'>
            <ul className="pagination">
                <li className={classNames('page-item', {'disabled': !previous})}>
                    <button className="page-link" onClick={()=>{handlePage(-1)}}>
                        <span>Anterior</span>
                    </button>
                </li>
                <li className={classNames('page-item')}>
                    <span className="page-link">{parseInt(searchParams.get('page') || 1)} de {num_pages}</span>
                </li>
                <li className={classNames('page-item', {'disabled': !next})}>
                    <button className="page-link" onClick={()=>{handlePage(1)}}>
                        <span>Siguiente</span>
                    </button>
                </li>
            </ul>
        </div>;
}

export default Pagination;