export const menuConfig = {
    catalogs: {
        title: 'Catálogos',
        submenus: [
            {
                title: 'Clientes',
                link: '/customers',
                permission: 'customers.cus_view_customer'
            },
            {
                title: 'Departamentos',
                link: '/departments',
                permission: 'departments.cus_view_department'
            },
            {
                title: 'Roles',
                link: '/roles',
                permission: 'users.cus_view_role'
            },
            {
                title: 'Vendedores',
                link: '/sellers',
                permission: 'sellers.cus_view_seller'
            },
            {
                title: 'Usuarios',
                link: '/users',
                permission: 'users.cus_view_user'
            }
        ]
    },
    sales: {
        title: 'Ventas',
        submenus: [
            {
                title: 'Biblioteca de artículos',
                link: '/quotes/items',
                permission: 'quotes.cus_view_quote'
            },
            {
                title: 'Cotizaciones',
                link: '/quotes',
                permission: 'quotes.cus_view_quote'
            },
            {
                title: 'Listas de precios',
                link: '/quotes/prices',
                permission: 'quotes.cus_view_quote'
            },
            /*{
                title: 'Leads',
                link: '/leads',
                permission: 'leads.cus_view_lead'
            }*/
        ]
    },
    /*reports: {
        title: 'Reportes',
        submenus: [
            {
                title: 'Leads',
                link: '/reports/leads',
                permission: 'leads.cus_view_leads_report'
            }
        ]
    }*/
}