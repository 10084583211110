import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import UserService from '../../services/user.service';
import { setSuccessMessage, displayErrorMessages } from '../../utils/messages';
import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';


const DeleteUserForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDeleting, setDeleting] = useState(false);

    const loadUser = async () => {
        UserService.getUser(id)
        .then(response => {
            setUser(response.data)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isDeleting) {
          return;
        }
        setDeleting(true);
        try {
          await UserService.deleteUser(id);
          setSuccessMessage('Usuario eliminado exitosamente');
          navigate('/users');
        } catch (error) {
          displayErrorMessages(error.response.data);
        } finally {
          setDeleting(false);
        }
    };

    useEffect(() => {
        loadUser();
    }, [])

    return <Layout title='Eliminar usuario'>
        <CardMenu>
            <div role="alert" className="fade bg-white alert alert-danger show">
                <h4 className="alert-heading">¿Deseas eliminar el usuario {user.email}?</h4>
                <p>Esta acción no se puede deshacer</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-secondary' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/users')}>Cancelar</button>
                        <button type='submit' className='btn btn-danger' disabled={isDeleting}>{isDeleting ? 'Eliminando...' : 'Eliminar'}</button>
                    </div>
                </div>
            </form>            
        </CardMenu>
    </Layout>
}

export default DeleteUserForm;