import { useSelector } from 'react-redux';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import Select from 'react-select';

import { hasPermissions } from '../../utils/permissions';
import CustomerService from '../../services/customer.service';
import { checkSuccessMessage } from '../../utils/messages';
import Pagination from '../layout/components/Pagination';
import Actions from '../layout/components/Actions';
import Layout from '../layout/Layout'
import CardMenu from '../layout/components/CardMenu'
import Modal from '../layout/components/Modal';
import UtilsService from '../../services/utils.service';


const CustomerList = () => {
    const permissions = useSelector(state => state.Auth.permissions)
    const modalRef = useRef()
    const location = useLocation()
    const currentPath = encodeURIComponent(location.pathname + location.search);
    const [loadingData, setLoadingData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pagination, setPagination] = useState({previous: null, next: null, num_pages: null})
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const filters = {
        q: searchParams.get('q'),
        seller: searchParams.getAll('seller').filter(element => {return element !== ''}),
        page: searchParams.get('page') || 1,
    }

    const [selectedSellers, setSelectedSellers] = useState([])
    const [sellers, setSellers] = useState([])
    const loadSellers = async () => {
        UtilsService.getRelatedField('sellers', 'Seller')
        .then((response) => {
            const res = response.data.map((m) => {
                return {value: m.id, label: m.label};
            });
            setSellers(res);

            const urlSellers = res.map((obj)=>{
                if(filters['seller'].includes(obj.value.toString())) return obj
            });
            setSelectedSellers(urlSellers);
        })
    }
    const sellerOptions = useMemo(() => {
        const filtered = sellers.filter((seller) =>
            seller.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [sellers, searchTerm]);

    const [customers, setCustomers] = useState([])
    const loadCustomers = async () => {
        setLoadingData(true);
        CustomerService.getCustomers(filters)
        .then((response) => {
            setCustomers(response.data.results);
            setPagination({
                previous: response.data.previous,
                next: response.data.next,
                num_pages: response.data.num_pages
            })
            setLoadingData(false);
        })
    }

    useEffect(() => {
        loadSellers();
        loadCustomers();
        checkSuccessMessage();
    }, []);

    return <Layout title='Clientes'>
        <CardMenu>
            <div className="mb-3 text-end">
                <button className='btn btn-light me-1' onClick={() => {modalRef.current.toggleModal()}}>Filtros</button>
                {hasPermissions(['customers.cus_add_customer'], permissions) && 
                    <Link 
                    to={'/customers/add' + '?prev_url=' + currentPath}
                    className='btn btn-primary'>
                        Nuevo cliente
                    </Link>
                }
            </div>
            <div className='table-responsive table-grid'>
                <table className='table-centered table table-bordered'>
                    <thead className='table-light'>
                        <tr>
                            <th>Empresa</th>
                            <th>Vendedor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loadingData ?
                                customers.length ? 
                                    customers.map((obj, i) => {
                                        return <tr key={i}>
                                            <td>{obj.company}</td>
                                            <td>{obj.seller?.identifier} - {obj.seller?.user?.first_name} {obj.seller?.user?.last_name}</td>
                                            <td>
                                                <Actions
                                                actions={[
                                                    {'label': 'Contactos', 'link': 'customers/' + obj.id + '/contacts' + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_view_contact'], permissions)},
                                                    {'label': 'Datos de facturación', 'link': 'customers/' + obj.id + '/billing-profiles' + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_view_billingprofile'], permissions)},
                                                    {'label': 'Detalles', 'link': 'customers/' + obj.id + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_view_customer'], permissions)},
                                                    {'label': 'Editar', 'link': 'customers/edit/' + obj.id  + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_change_customer'], permissions)},
                                                    {'label': 'Eliminar', 'link': 'customers/delete/' + obj.id  + '?prev_url=' + currentPath, 'hasPerm': hasPermissions(['customers.cus_delete_customer'], permissions)}
                                                ]}></Actions>
                                            </td>
                                        </tr>
                                })
                                :<tr><td colSpan={6}><span className='text-danger'>No se encontraron resultados</span></td></tr>
                            :<tr><td colSpan={6}><div className="d-flex justify-content-center"><div className="spinner-border avatar-md text-primary"></div></div></td></tr>
                        }
                    </tbody>
                </table>
                <Pagination previous={pagination['previous']} next={pagination['next']} num_pages={pagination['num_pages']}></Pagination>
            </div>
        </CardMenu>

        <Modal title='Filtros' ref={modalRef}>
            <form method='GET'>
                <div className="mb-3">
                    <label htmlFor="search" className="me-2">Palabra clave</label>
                    <input type="text" className='form-control w-100' name='q' defaultValue={filters['q']} />
                </div>
                <div className='mb-3'>
                    <label htmlFor="seller" className="me-2">Vendedor</label>
                    <Select
                        name='seller'
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        isMulti
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={sellerOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setSelectedSellers(option)}}
                        value={selectedSellers}>
                    </Select>
                </div>
                <div className="mb-3 text-end">
                    <input type="submit" className='btn btn-primary' value='Buscar' />
                </div>
            </form>
        </Modal>

    </Layout>
}

export default CustomerList