import DepartmentList from '../../components/departments/DepartmentList'
import DepartmentForm from '../../components/departments/DepartmentForm'
import DepartmentDetail from '../../components/departments/DepartmentDetail'
import DeleteDepartmentForm from '../../components/departments/DeleteDepartmentForm'


const departmentRoutes = [
    {path: '/departments', permission: ['departments.cus_view_department'], component: <DepartmentList/>},
    {path: '/departments/:id', permission: ['departments.cus_view_department'], component: <DepartmentDetail/>},
    {path: '/departments/add', permission: ['departments.cus_add_department'], component: <DepartmentForm/>},
    {path: '/departments/edit/:id', permission: ['departments.cus_change_department'], component: <DepartmentForm/>},
    {path: '/departments/delete/:id', permission: ['departments.cus_delete_department'], component: <DeleteDepartmentForm/>}
]

export default departmentRoutes