import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';

import Layout from '../../layout/Layout';
import CardMenu from '../../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../../utils/messages';
import CustomerService from '../../../services/customer.service';
import UtilsService from '../../../services/utils.service';


const BillingProfileForm = () => {
    const navigate = useNavigate();
    const { customerId, id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [cfdi, setCfdi] = useState([]);
    const [selectedCfdi, setSelectedCfdi] = useState();
    const loadCfdi = async (reload=false) => {
        UtilsService.getFieldChoices('customers', 'BillingProfile', 'cfdi')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt[0], label: opt[1]};
            });
            setCfdi(res);
        })
    }

    const [taxRegime, setTaxRegime] = useState([]);
    const [selectedTaxRegime, setSelectedTaxRegime] = useState();
    const loadTaxRegime = async (reload=false) => {
        UtilsService.getFieldChoices('customers', 'BillingProfile', 'tax_regime')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt[0], label: opt[1]};
            });
            setTaxRegime(res);
        })
    }

    let formFields = {
        customer: yup.string(),
        business_name: yup.string().required('Este campo es requerido'),
        rfc: yup.string().required('Este campo es requerido'),
        cfdi: yup.string().required('Este campo es requerido'),
        tax_regime: yup.string().required('Este campo es requerido'),
        line1: yup.string().required('Este campo es requerido'),
        line2: yup.string(),
        postal_code: yup.string().required('Este campo es requerido'),
        state: yup.string().required('Este campo es requerido'),
        municipality: yup.string().required('Este campo es requerido'),
    }
    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            customer: customerId
        }
    });

    const onSubmitHandler = async (data) => {
        setSaving(true)
        try {
            if (!id) {
                await CustomerService.createBillingProfile(data);
                setSuccessMessage('Datos de facturación creados exitosamente');
            } else {
                await CustomerService.updateBillingProfile(id, data);
                setSuccessMessage('Datos de facturación actualizados correctamente');
            }
            navigate('/customers/' + customerId + '/billing-profiles');
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };

    const loadCustomer = async () => {
        CustomerService.getBillingProfile(id)
        .then(response => {
            setValue('business_name', response.data.business_name)
            setValue('rfc', response.data.rfc)
            setValue('cfdi', response.data.cfdi)
            setSelectedCfdi({value: response.data.cfdi, label: response.data.cfdi_display})
            setValue('tax_regime', response.data.tax_regime)
            setSelectedTaxRegime({value: response.data.tax_regime, label: response.data.tax_regime_display})
            setValue('line1', response.data.line1)
            setValue('line2', response.data.line2)
            setValue('postal_code', response.data.postal_code)
            setValue('state', response.data.state)
            setValue('municipality', response.data.municipality)
        })
    }

    useEffect(() => {
        loadCfdi();
        loadTaxRegime();
        if(id){
            loadCustomer();
        }
    }, []);


    return <Layout title={!id ? 'Nuevos datos de facturación': 'Editar datos de facturación'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
            <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['business_name'].exclusiveTests.required && <span className="text-danger">*</span>} Razón social</label>
                    <input type="text" className='form-control' {...register('business_name')} />
                    {errors.business_name ? <div className='text-danger'>{errors.business_name?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['rfc'].exclusiveTests.required && <span className="text-danger">*</span>} RFC</label>
                    <input type="text" className='form-control' {...register('rfc')} />
                    {errors.rfc ? <div className='text-danger'>{errors.rfc?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['cfdi'].exclusiveTests.required && <span className="text-danger">*</span>} Uso de CFDI</label>

                    <Controller
                    name="cfdi"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={cfdi}
                    onChange={(option, action) => {setValue('cfdi', option ? option.value : ''); setSelectedCfdi(option)}}
                    value={selectedCfdi}>
                    </Select>
        
                    {errors.cfdi ? <div className='text-danger'>{errors.cfdi?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['tax_regime'].exclusiveTests.required && <span className="text-danger">*</span>} Régimen fiscal</label>

                    <Controller
                    name="tax_regime"
                    control={control}
                    render={({ field }) => (
                        <input type="hidden" {...field} value={field.value} />
                    )}
                    >
                    </Controller>
                    
                    <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                    placeholder="Selecciona una opción"
                    noOptionsMessage={() => 'No se encontraron resultados'}
                    options={taxRegime}
                    onChange={(option, action) => {setValue('tax_regime', option ? option.value : ''); setSelectedTaxRegime(option)}}
                    value={selectedTaxRegime}>
                    </Select>
        
                    {errors.tax_regime ? <div className='text-danger'>{errors.tax_regime?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['line1'].exclusiveTests.required && <span className="text-danger">*</span>} Línea 1 de la dirección</label>
                    <input type="text" className='form-control' {...register('line1')} />
                    {errors.line1 ? <div className='text-danger'>{errors.line1?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['line2'].exclusiveTests.required && <span className="text-danger">*</span>} Línea 2 de la dirección</label>
                    <input type="text" className='form-control' {...register('line2')} />
                    {errors.line2 ? <div className='text-danger'>{errors.line2?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['state'].exclusiveTests.required && <span className="text-danger">*</span>} Estado</label>
                    <input type="text" className='form-control' {...register('state')} />
                    {errors.state ? <div className='text-danger'>{errors.state?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['municipality'].exclusiveTests.required && <span className="text-danger">*</span>} Municipio</label>
                    <input type="text" className='form-control' {...register('municipality')} />
                    {errors.municipality ? <div className='text-danger'>{errors.municipality?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['postal_code'].exclusiveTests.required && <span className="text-danger">*</span>} Código postal</label>
                    <input type="text" className='form-control' {...register('postal_code')} />
                    {errors.postal_code ? <div className='text-danger'>{errors.postal_code?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers/' + customerId + '/billing-profiles')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default BillingProfileForm