import { useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { login, logout } from '../../redux/actions/auth';
import AuthService from '../../services/auth.service';
import { displayErrorMessages } from '../../utils/messages';


const LoginModal = forwardRef((props, ref) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    };

    useImperativeHandle(ref, () => ({
        toggleModal(){
            toggle();
        }
    }));

    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const schema = yup.object().shape({
        email: yup.string().required('Este campo es requerido'),
        password: yup.string().required('Este campo es requerido'),
      }).required();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
        setSaving(true)
        try {
            const response = await AuthService.login(data['email'], data['password'])
            if (response.status === 200) {
                dispatch(login(response))
                toggle();
            }
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };

    return <Modal show={modal} onHide={toggle} backdrop="static" keyboard={false}>
            <Modal.Header onHide={toggle}>
                <div className='modal-title h4'>
                    Tu sesión ha caducado, si deseas seguir trabajando inicia sesión.
                </div>
            </Modal.Header>
            <Modal.Body>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-3">
                        <label className="form-label">Correo electrónico</label>
                        <input placeholder="Ingresa tu correo electrónico" name="email" type="email" id="email" className="form-control" {...register('email')}/>
                        {errors.email ? <div className='text-danger'>{errors.email?.message}</div> : ''}
                    </div>
                    <div className="mb-3"> 
                        <label className="form-label">Contraseña</label>
                        <div className="mb-0 input-group">
                            <input placeholder="Ingresa tu contraseña" name="password" type={showPassword ? 'text' : 'password'} id="password" className="form-control" {...register('password')}/>
                            <div className={classNames('input-group-text', 'input-group-password', {'show-password': showPassword,})} data-password={showPassword ? 'true' : 'false'}>
                                <span className="password-eye" onClick={() => {setShowPassword(!showPassword);}}></span>
                            </div>
                        </div>
                        {errors.password ? <div className='text-danger'>{errors.password?.message}</div> : ''}
                    </div>
                    <div className="mb-3 mb-0 text-center">
                        <button type="submit" className="btn btn-dark" disabled={isSaving}>{isSaving ? 'Espera un momento...':'Iniciar sesión'}</button>
                    </div>
                </form>
                
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-light' onClick={() => {dispatch(logout());}}>Cerrar sesión</button>
            </Modal.Footer>
        </Modal>;
});

export default LoginModal;