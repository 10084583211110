import { 
    LOGIN_SUCCESS,
    LOGOUT,
} from '../actions/types';


export const login = (response) => async dispatch => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data
    });
}

export const logout = () => async dispatch => {
    dispatch({
        type: LOGOUT
    });
}