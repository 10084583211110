import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages } from '../../utils/messages';
import UserService from '../../services/user.service';


const PasswordForm = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const formFields = {
        password: yup.string().required('Este campo es requerido')
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,16}$/,'El formato de la contraseña es incorrecto'),
        confirm_password: yup.string().required('Este campo es requerido').oneOf([yup.ref('password'), null], 'La contraseña no coincide')
    }

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
        setSaving(true)
        try {
            await UserService.updatePassword(id, data);
            setSuccessMessage('Contraseña actualizada correctamente');
            navigate('/users');
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };
    
    const loadUser = async () => {
        UserService.getUser(id)
        .then(response => {
            setUser(response.data)
        })
    }

    useEffect(() => {
        loadUser();
    }, [])

    return <Layout title='Reestablecer contraseña'>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Contraseña</label>
                    <input type="password" className='form-control' {...register('password')}/>
                    <small className="form-text">La contraseña debe incluir al menos un número, una mayúscula, una minúscula y debe tener entre 10-16 caracteres.</small>
                    {errors.password ? <div className='text-danger'>{errors.password?.message}</div> : ''}
                </div>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>Confirmar contraseña</label>
                    <input type="password" className='form-control' {...register('confirm_password')}/>
                    {errors.confirm_password ? <div className='text-danger'>{errors.confirm_password?.message}</div> : ''}
                </div>
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/users')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default PasswordForm