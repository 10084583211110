import moment from 'moment';
import 'moment/locale/es-mx';

moment.locale('es-mx')


export const formatSessionTimeRemaining = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

export const getFirstDayOfMonth = () => {
    const today = moment();
    const firstDay = today.startOf('month');
    const formattedFirstDay = firstDay.format('YYYY-MM-DD');
    return formattedFirstDay;
}
  
export const getLastDayOfMonth = () => {
    const today = moment();
    const lastDay = today.endOf('month');
    const formattedLastDay = lastDay.format('YYYY-MM-DD');
    return formattedLastDay;
}