import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import Layout from '../layout/Layout';
import CardMenu from '../layout/components/CardMenu';
import { setSuccessMessage, displayErrorMessages} from '../../utils/messages';
import CustomerService from '../../services/customer.service';
import UtilsService from '../../services/utils.service';
import { showMessage } from '../../utils/messages';
import { hasPermissions } from '../../utils/permissions';


const CustomerForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSaving, setSaving] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const userPermissions = useSelector(state => state.Auth.permissions);
    const canViewCustomersFromOtherSellers = hasPermissions(['customers.cus_view_customers_from_other_sellers'], userPermissions)
    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (newValue) => {
        setSearchTerm(newValue);
    };

    const [sellers, setSellers] = useState([]);
    const [selectedSeller, setSelectedSeller] = useState();
    const loadSellers = async (reload=false) => {
        UtilsService.getRelatedField('sellers', 'Seller')
        .then(response => {
            const res = response.data.map((opt) => {
                return {value: opt.id, label: opt.label};
            });
            setSellers(res);
            if(reload) showMessage('success', 'Vendedores actualizados correctamente')
        })
    }
    const sellerOptions = useMemo(() => {
        const filtered = sellers.filter((seller) =>
            seller.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filtered.slice(0, 50);
    }, [sellers, searchTerm]);

    
    let formFields = {
        company: yup.string().required('Este campo es requerido')
    }

    if(canViewCustomersFromOtherSellers) formFields['seller'] = yup.string().required('Este campo es requerido')

    const schema = yup.object().shape(formFields).required();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = async (data) => {
        setSaving(true)
        try {
            if (!id) {
                await CustomerService.createCustomer(data);
                setSuccessMessage('Cliente creado exitosamente');
            } else {
                await CustomerService.updateCustomer(id, data);
                setSuccessMessage('Cliente actualizado correctamente');
            }
            navigate('/customers');
        } catch (error) {
            displayErrorMessages(error.response.data);
        } finally {
            setSaving(false);
        }
    };

    const loadCustomer = async () => {
        CustomerService.getCustomer(id)
        .then(response => {
            setValue('company', response.data.company)
            setValue('seller', response.data.seller.id)
            setSelectedSeller({value: response.data.seller.id, label: response.data.seller.identifier + ' - ' + response.data.seller?.user?.first_name + ' ' + response.data.seller?.user?.last_name})
        })
    }

    useEffect(() => {
        loadSellers();
        if(id) loadCustomer();
    }, []);


    return <Layout title={!id ? 'Nuevo cliente': 'Editar cliente'}>
        <CardMenu>
            <form className='row' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3 col-md-6'>
                    <label className='form-label'>{schema.fields['company'].exclusiveTests.required && <span className="text-danger">*</span>} Empresa</label>
                    <input type="text" className='form-control' {...register('company')} />
                    {errors.company ? <div className='text-danger'>{errors.company?.message}</div> : ''}
                </div>
                {
                    canViewCustomersFromOtherSellers &&
                    <div className='mb-3 col-md-6'>
                        <div className='d-flex'>
                            <label className='form-label ms-1'>{schema.fields['seller'].exclusiveTests.required && <span className="text-danger">*</span>} Ejecutivo</label>
                            {hasPermissions(['sellers.cus_add_seller'], userPermissions) &&
                            <div className='ms-1 mt-n1'>
                                (
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {window.open('/sellers/add', '', 'width='+ window.width/2 +',height=' + window.height/2)}}><i className='dripicons-plus'></i></button>
                                <button type='button' className='btn btn-link btn-icon btn-sm' onClick={() => {loadSellers(true)}}><i className='dripicons-retweet'></i></button>
                                )
                            </div>
                            }
                        </div>
                        
                        <Controller
                        name="seller"
                        control={control}
                        render={({ field }) => (
                            <input type="hidden" {...field} value={field.value} />
                        )}
                        >
                        </Controller>
                        
                        <Select
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="Selecciona una opción"
                        noOptionsMessage={() => 'No se encontraron resultados'}
                        options={sellerOptions}
                        filterOption={() => true}
                        onInputChange={handleInputChange}
                        onChange={(option, action) => {setValue('seller', option ? option.value : ''); setSelectedSeller(option)}}
                        value={selectedSeller}>
                        </Select>
            
                        {errors.seller ? <div className='text-danger'>{errors.seller?.message}</div> : ''}
                    </div>
                }
                <div className='col-12 border-top'>
                    <div className='d-flex justify-content-between mt-2'>
                        <button type='button' className='btn btn-danger' onClick={() => searchParams.get('prev_url') ? navigate(searchParams.get('prev_url')) : navigate('/customers')}>Cancelar</button>
                        <button type='submit' className='btn btn-primary' disabled={isSaving}>{isSaving ? 'Espera un momento...':'Guardar'}</button>
                    </div>
                </div>
            </form>
        </CardMenu>
    </Layout>
}

export default CustomerForm