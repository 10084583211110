import { useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import Header from './Header';
import Footer from './Footer';
import LoginModal from '../auth/LoginModal';


const Layout = (props) => {
    const {sessionExpiresAt} = useSelector(state => {
        return {sessionExpiresAt: state.Auth.expires_at }
    });
    const modalRef = useRef()

    useEffect(() => {
        document.body.setAttribute('data-layout', 'topnav');
        document.body.setAttribute('data-layout-color', 'light');
        document.body.setAttribute('data-layout-mode', 'fluid');
        document.body.setAttribute('data-leftbar-theme', 'default');
        document.body.setAttribute('data-leftbar-compact-mode', 'fixed');
    }, []);

    useEffect(() => {
        if (sessionExpiresAt) {
          const intervalId = setInterval(() => {
            const now = new Date();
            const expiresAtDate = new Date(sessionExpiresAt);
            const timeDiff = expiresAtDate - now;
            if (timeDiff <= 0) {
              clearInterval(intervalId);
              modalRef.current.toggleModal();
            }
          }, 1000);
    
          return () => clearInterval(intervalId);
        }
      }, [sessionExpiresAt]);

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            
            <LoginModal ref={modalRef}></LoginModal>
        
            <div className="wrapper">
                <div className="content-page">
                    <div className="content">
                        <Header></Header>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <div className="page-title-box">
                                        <h4 className="page-title">{props.title}</h4>
                                    </div>
                                </div>
                            </div>
                            {props.children}
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
        </>
    );
}

export default Layout;




/**
 * {props.children}
 * Esto pued ir en el body
 * data-layout="topnav" data-layout-color="light" data-layout-mode="fluid" data-leftbar-theme="default" data-leftbar-compact-mode="fixed"
 */